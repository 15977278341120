function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
export var PROJECT_ENTITY_ID = 1;
export var EVENT_ENTITY_ID = 2;
export var PLANNER_ENTITY_ID = 3;
export var CLIENT_ENTITY_ID = 4;
export var CONTACT_ENTITY_ID = 5;
export var EMPLOYEE_ENTITY_ID = 6;
export var EXTERNAL_STAFF_PROVIDER_ENTITY_ID = 7;
export var ENTITY_IDS = [
    PROJECT_ENTITY_ID,
    EVENT_ENTITY_ID,
    PLANNER_ENTITY_ID,
    CLIENT_ENTITY_ID,
    CONTACT_ENTITY_ID,
    EMPLOYEE_ENTITY_ID,
    EXTERNAL_STAFF_PROVIDER_ENTITY_ID
];
var _obj;
export var entityToString = (_obj = {}, _define_property(_obj, PROJECT_ENTITY_ID, 'project'), _define_property(_obj, EVENT_ENTITY_ID, 'event'), _define_property(_obj, PLANNER_ENTITY_ID, 'planner'), _define_property(_obj, CLIENT_ENTITY_ID, 'client'), _define_property(_obj, CONTACT_ENTITY_ID, 'contact'), _define_property(_obj, EMPLOYEE_ENTITY_ID, 'employee'), _define_property(_obj, EXTERNAL_STAFF_PROVIDER_ENTITY_ID, 'external staff provider'), _obj);
