import PropTypes from 'prop-types';
var EmployeeType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    dyn_attr: PropTypes.object.isRequired,
    status: PropTypes.number.isRequired,
    fullName: PropTypes.string,
    created_at: PropTypes.string,
    firstname: PropTypes.string,
    has_conflicts: PropTypes.bool,
    hasAppliedDate: PropTypes.bool,
    is_unavailable: PropTypes.bool,
    lacks_qualifications: PropTypes.bool,
    lastname: PropTypes.string,
    mobile: PropTypes.string,
    picture: PropTypes.string,
    status_name: PropTypes.string,
    updated_at: PropTypes.string
});
export { EmployeeType };
