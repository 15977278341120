function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import _ from 'lodash';
import { App } from 'Shared/resources/assets/app/js/App';
import { onDocumentReady } from 'Shared/resources/assets/app/js/helpers/domHelpers';
import 'Shared/resources/assets/ckeditor4';
import { Popup } from 'Shared/resources/assets/app/js/ui/libs/Popup';
var WysiwygEditor = {
    /**
     * Editor assets
     */ basePath: '/dist/ckeditor4',
    /**
     * Initializes a new editor instance
     *
     * @param {string|object} target  ID of target text area or the dom element
     * @param {object}        options The options for the newly initiated CKEDITOR
     */ init: function init(target, options) {
        var _this = this;
        // Make sure DOM is loaded entirely, just in case somebody calls init too early...
        onDocumentReady(function() {
            _this.loadEditor(target, options);
        });
    },
    /**
     * Replaces target object with editor
     *
     * @param {string|object} target  ID of target text area, or simply the textarea dom object
     * @param {object}        options The options to be passed to the CKEDITOR
     */ loadEditor: function loadEditor(target) {
        var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        var defaultOptions = {
            initMethod: 'replace',
            settings: {},
            plugins: '',
            pluginAttributes: '',
            onInstanceReadyCallback: function() {}
        };
        var mergedOptions = _.defaults({}, options, defaultOptions);
        var targetObject = _.isString(target) ? document.getElementById(target) : target;
        // Call editor's internal loader
        CKEDITOR[mergedOptions.initMethod](target, _object_spread({
            width: '100%',
            height: getComputedStyle(targetObject).height,
            extraAllowedContent: 'span(placeholder,name,editor-attribute,entity)[contenteditable,class]',
            extraPlugins: mergedOptions.plugins,
            on: {
                instanceReady: function(event) {
                    mergedOptions.onInstanceReadyCallback(event);
                    Popup.parentDocument(true).adjustHeight();
                },
                pluginsLoaded: function pluginsLoaded(event) {
                    event.editor.dataProcessor.dataFilter.addRules({
                        comment: function() {
                            return false;
                        }
                    });
                }
            }
        }, mergedOptions.settings));
        CKEDITOR.config['attributes'] = mergedOptions.pluginAttributes;
    },
    /**
     * Destroys editor instance.
     *
     * @param {string} target ID of target text area
     */ destroyEditor: function destroyEditor(target) {
        CKEDITOR.instances[target].destroy(true);
    },
    /**
     * Destroy all editor instances.
     */ destroyAll: function destroyAll() {
        for(var instanceName in CKEDITOR.instances){
            CKEDITOR.instances[instanceName].destroy(true);
        }
    },
    /**
     * Since the editor only updates on form submit you may want to get the content manually.
     *
     * @param {string} target ID of target text area
     *
     * @returns {string} HTML
     */ getData: function getData(target) {
        return CKEDITOR.instances[target].getData();
    },
    /**
     * Returns plain text content of editor.
     *
     * @param {string} target ID of target text area
     *
     * @returns {string} Plain text
     */ getPlainText: function getPlainText(target) {
        return CKEDITOR.instances[target].editable().getText();
    },
    /**
     * Update the values of all editor instances.
     *
     * This method may be required for example for validation.
     * If this method is not being called, the value will only update on form submit.
     */ updateAll: function updateAll() {
        if (typeof CKEDITOR === 'undefined') {
            return;
        }
        for(var instanceName in CKEDITOR.instances){
            if (CKEDITOR.instances.hasOwnProperty(instanceName)) {
                CKEDITOR.instances[instanceName].updateElement();
            }
        }
    }
};
// TODO: remove the following line when its use will be removed from blade.php files
App.WysiwygEditor = WysiwygEditor;
export { WysiwygEditor };
