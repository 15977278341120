function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import $ from 'jquery';
import _ from 'lodash';
import { App } from 'Shared/resources/assets/app/js/App';
import { i18n, ts, unformatNumber } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { config } from 'Shared/resources/assets/app/js/helpers/configHelpers';
import { fire } from 'Shared/resources/assets/app/js/helpers/eventHelpers';
import { error } from 'Shared/resources/assets/app/js/helpers/notificationHelpers';
import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
import { FormBuilder, Popup, WysiwygEditor } from 'Shared/resources/assets/app/js/ui/libs';
var topWindowDocument = getTopWindowDocument();
var Validator = {
    /**
     * Event which will be fired on validation failure.
     */ VALIDATION_ERROR_EVENT: 'VALIDATION_ERROR_EVENT',
    /**
     * Event which will be fired on validation success.
     */ VALIDATION_SUCCESS_EVENT: 'VALIDATION_SUCCESS_EVENT',
    /**
     * INT64 limits.
     */ INT64_MIN: '-9223372036854775808',
    INT64_MAX: '9223372036854775807',
    /**
     * CSS class of input container
     */ groupClass: 'ui-form-group',
    /**
     * CSS class of error message
     */ errorClass: 'ui-form-hint',
    /**
     * CSS class of container that contains an error message
     * This class will be applied to container if an error occurs
     */ errorGroupClass: 'ui-form-group-negative',
    /**
     * These types are storing their actual values in hidden inputs
     */ specialTypesClass: [
        'ui-form-element-multi-select',
        'ui-form-element-level-select'
    ],
    /**
     * Defines which validators are available for which elements
     */ applicableValidators: {
        input: [
            'nullable',
            'required',
            'alpha',
            'alpha_num',
            'alpha_num_dash',
            'min_length',
            'max_length',
            'min',
            'max',
            'email',
            'phone',
            'url',
            'date',
            'time',
            'date_time',
            'number',
            'positive_number',
            'float',
            'currency',
            'file',
            'same',
            'decimal',
            'color',
            'before_or_equal',
            'after_or_equal'
        ],
        textArea: [
            'nullable',
            'required',
            'min_length',
            'max_length'
        ],
        wysiwyg: [
            'nullable',
            'required',
            'min_length',
            'max_length'
        ],
        select: [
            'nullable',
            'required'
        ],
        multiSelect: [
            'nullable',
            'required'
        ],
        levelSelect: [
            'nullable',
            'required'
        ],
        checkbox: [
            'nullable',
            'required'
        ],
        file: [
            'nullable',
            'required',
            'file'
        ]
    },
    init: function init(formId, config, alertErrors, onFailure, onSuccess) {
        var checkRequiredFields = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : true;
        alertErrors = alertErrors || false;
        onFailure = onFailure || function() {};
        onSuccess = onSuccess || function() {};
        // Laravel validator keys use dot notation 'input.0' but our input ids use bracket notation 'input[0]'
        // So we need to transform the id from dot to bracket notation.
        _.forEach(config, function(validators, index) {
            var bracketNotationKey = index.replace(/[.]([^\.]*)/g, '[$1]');
            if (bracketNotationKey != index) {
                config[bracketNotationKey] = config[index];
                delete config[index];
            }
        });
        this.setConfig(formId, config || {});
        // Mark required elements
        this.markElements(config);
        var form = $('#' + formId);
        form.on('submit', this.onFormSubmit.bind(this, {
            alertErrors: alertErrors,
            form: form,
            formId: formId,
            onFailure: onFailure,
            onSuccess: onSuccess,
            checkRequiredFields: checkRequiredFields
        }));
    },
    addFiles: function addFiles(formId) {
        var files = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        _.setWith(topWindowDocument, [
            'validator',
            formId,
            'files'
        ], _object_spread({}, this.files(formId), files), Object);
    },
    removeFiles: function removeFiles(formId, fileId) {
        _.setWith(topWindowDocument, [
            'validator',
            formId,
            'files'
        ], _.omit(this.files(formId), [
            fileId
        ]), Object);
    },
    files: function files(formId) {
        return _.get(topWindowDocument, [
            'validator',
            formId,
            'files'
        ], {});
    },
    setConfig: function setConfig(formId) {
        var config = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        _.setWith(topWindowDocument, [
            'validator',
            formId,
            'config'
        ], config, Object);
    },
    config: function config(formId) {
        return _.get(topWindowDocument, [
            'validator',
            formId,
            'config'
        ], {});
    },
    onFormSubmit: function onFormSubmit(param, e) {
        var alertErrors = param.alertErrors, form = param.form, formId = param.formId, onFailure = param.onFailure, onSuccess = param.onSuccess, checkRequiredFields = param.checkRequiredFields;
        if (Object.hasOwn(e, 'originalEvent') && e.originalEvent.submitter.hasAttribute('data-validator-skip-required-fields-check')) {
            checkRequiredFields = false;
        }
        // Update the values of all WYSIWYG editor instances
        WysiwygEditor.updateAll();
        var passed = this.validateForm(formId, this.config(formId), alertErrors, checkRequiredFields);
        if (!passed) {
            var // Scroll to first error
            _$_get;
            // If validation failed, enable fake inputs for custom form elements
            // User must be able to edit them again
            FormBuilder.enableFakeInputs(form);
            // Also adjust the height of the popup, if any
            Popup.adjustHeight();
            (_$_get = $('.' + this.errorGroupClass).get(0)) === null || _$_get === void 0 ? void 0 : _$_get.scrollIntoView();
            // Execute failure callback
            onFailure(form);
            // Fire failure event
            fire(this.VALIDATION_ERROR_EVENT);
        } else {
            // Execute passed callback
            onSuccess(form);
            // Fire success event
            fire(this.VALIDATION_SUCCESS_EVENT);
            if ($(form).hasClass('prevent-multiple-submit')) {
                $(form).addClass('submitted');
            }
        }
        return passed;
    },
    findElement: function findElement(idOrName) {
        // HTML5 has no more limitations regarding characters used in class names.
        // However jQuery is not able to handle certain characters like square brackets.
        // We have to escape them here...
        idOrName = idOrName.replace(/(:|\.|\[|\]|,)/g, '\\$1');
        var $element;
        var selectors = [
            "#".concat(idOrName),
            "[name=".concat(idOrName, "]")
        ];
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = Object.entries(_object_spread({}, selectors))[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var _step_value = _sliced_to_array(_step.value, 2), index = _step_value[0], selector = _step_value[1];
                $element = $(selector);
                if ($element.length >= 1) {
                    break;
                }
                if (parseInt(index) + 1 === selectors.length) {
                    return null;
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
        // Check if element has a special types class
        // In this case the actual value is stored in a hidden input
        _.forEach(this.specialTypesClass, function(className) {
            if ($element.hasClass(className)) {
                $element = $element.find('input[type="hidden"]');
            }
        });
        // If the element is still a DIV, we have a problem
        // Try to find any input within the element
        // This applies for example for checkboxes
        if ($element.prop('tagName').toUpperCase() === 'DIV') {
            $element = $element.find('input:not(.checkbox-proxy)');
        }
        return $element;
    },
    findGroup: function findGroup(element) {
        return element.closest('.' + this.groupClass);
    },
    markElements: function markElements(config) {
        _.forEach(config, (function(validator, index) {
            // TODO: THIS IS NOT OK AND MUST BE REMOVED
            // TODO: A rule must be verified before being added to the validator, not on the fly
            if (_.isObject(validator.required) && validator.required.hasOwnProperty('condition') && validator.required.condition === true && validator.required.value === true) {
                return;
            }
            // Only mark required elements
            // Other elements may be empty but valid
            if (validator.required === true || validator.minLength === true) {
                var element = this.findElement(index);
                // If no element is found there is nothing to do
                if (!element) {
                    return;
                }
                var group = this.findGroup(element);
                // Since checkboxes usually don't have a label,
                // we need to add the star to their name
                var label = group.find('label, .ui-form-element-checkbox').first().contents().last();
                // Finally add the star to the text of the node
                label.replaceWith(label.text().replace(' *', '') + ' *');
            }
        }).bind(this));
    },
    validateForm: function validateForm(formId, config, alertErrors) {
        var checkRequiredFields = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : true;
        var passed = true;
        // Loop trough elements based on config, not HTML
        _.forEach(config, (function(validators, index) {
            var element = this.findElement(index);
            // If no element is found there is nothing to do
            if (!element) {
                return;
            }
            var group = this.findGroup(element);
            if (group.hasClass('ui-hidden-by-condition')) {
                return;
            }
            var type = this.determineType(element);
            var value = this.getValue(element, type);
            // Remove existing error messages
            group.removeClass(this.errorGroupClass);
            group.find(".".concat(this.errorClass, ":not(.ui-form-file-hint)")).remove();
            // Loop trough validators
            _.forEach(validators, (function(args, name) {
                // Check if validator is applicable for current element
                var applicableValidators = this.applicableValidators[type];
                if (applicableValidators.length < 1 || !applicableValidators.includes(name)) {
                    throw "Validator not allowed for field type ".concat(type);
                }
                if (name === 'required' && args === false) {
                    return;
                }
                // TODO: THIS IS NOT OK AND MUST BE REMOVED
                // TODO: A rule must be verified before being added to the validator, not on the fly
                if (_.isObject(args) && args.hasOwnProperty('condition') && args.condition !== true) {
                    return;
                }
                // Validate element
                if (!this.isValid(value, name, args, index, type, formId, checkRequiredFields)) {
                    passed = false;
                    // Show error message
                    this.showError(group, name, args, alertErrors);
                }
            }).bind(this));
        }).bind(this));
        return passed;
    },
    isValid: function isValid(value, validator, args, elementIdOrName, type, formId) {
        var checkRequiredFields = arguments.length > 6 && arguments[6] !== void 0 ? arguments[6] : true;
        var expr = '';
        var trimmedValue = '';
        var number;
        var element;
        if (typeof elementIdOrName === 'string') {
            element = this.findElement(elementIdOrName);
        }
        switch(validator){
            case 'required':
                if (!checkRequiredFields) {
                    return true;
                }
                if (type !== 'file') {
                    if (typeof value === 'undefined') {
                        return false;
                    }
                    if (typeof value === 'object' && _.isEmpty(_.pickBy(value, function(value) {
                        return value;
                    }))) {
                        return false;
                    }
                    if (value === '' || value === null || value === false) {
                        return false;
                    }
                } else {
                    var files = this.files(formId);
                    if (value === false && !files.hasOwnProperty(elementIdOrName)) {
                        return false;
                    }
                }
                break;
            case 'alpha':
                expr = /^[a-zA-ZÀ-ÿ]+$/i;
                if (value.trim().length > 0 && !expr.test(value)) {
                    return false;
                }
                break;
            case 'alpha_num':
                expr = /^[a-zA-ZÀ-ÿ0-9]+$/i;
                if (value.trim().length > 0 && !expr.test(value)) {
                    return false;
                }
                break;
            case 'alpha_num_dash':
                expr = /^[a-zA-Z0-9-_]+$/i;
                if (value.trim().length > 0 && !expr.test(value)) {
                    return false;
                }
                break;
            case 'min_length':
                if (value.trim().length > 0 && value.length < parseInt(args)) {
                    return false;
                }
                break;
            case 'max_length':
                if (value.trim().length > 0 && value.length > parseInt(args)) {
                    return false;
                }
                break;
            case 'min':
                if (parseInt(value) < parseInt(args)) {
                    return false;
                }
                break;
            case 'max':
                if (parseInt(value) > parseInt(args)) {
                    return false;
                }
                break;
            case 'email':
                expr = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
                if (value.length > 0 && !expr.test(value)) {
                    return false;
                }
                break;
            case 'phone':
                expr = /^(?:\+|00|011)[\d. ()-]{7,}$/;
                if (value.length > 0 && !expr.test(value)) {
                    return false;
                }
                break;
            case 'url':
                expr = /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
                if (value.length > 0 && !expr.test(value)) {
                    return false;
                }
                break;
            case 'date':
                var date = Date.parse(value);
                if (value.length > 0 && isNaN(date) === true) {
                    return false;
                }
                break;
            case 'time':
                expr = /^\d{1,2}:\d{2}([ap]m)?$/;
                if (value.length > 0 && !expr.test(value)) {
                    return false;
                }
                break;
            case 'date_time':
                var parts = value.split(' ');
                if (!this.isValid(parts[0], 'date', args, elementIdOrName, type, formId)) {
                    return false;
                }
                if (typeof parts[1] !== 'undefined' && !this.isValid(parts[1], 'time', args, elementIdOrName, type, formId)) {
                    return false;
                }
                break;
            case 'before_or_equal':
            case 'after_or_equal':
                if (value.length === 0 || !this.isValid(value, 'date', args, elementIdOrName, type, formId) && !this.isValid(value, 'date_time', args, elementIdOrName, type, formId)) {
                    break;
                }
                var dateValue = new Date(Date.parse(value));
                var beforeOrAfter = new Date();
                if (args.toLowerCase() !== 'now') {
                    beforeOrAfter = new Date(Date.parse(args));
                    // Set time to midnight in case a simple format (Y-m-d) was used.
                    if (args.split(' ').length === 1) {
                        beforeOrAfter.setHours(0, 0, 0, 0);
                    }
                }
                return validator === 'before_or_equal' ? dateValue <= beforeOrAfter : dateValue >= beforeOrAfter;
            case 'number':
                expr = /^[-]?[0-9]+$/;
                number = this.getUnformattedNumber(value, expr, element);
                if (number.length > 0 && String(number).match(expr) === null) {
                    return false;
                }
                var isNegativeNumber = number.indexOf('-') === 0;
                if (isNegativeNumber && BigInt(number) < BigInt(this.INT64_MIN) || !isNegativeNumber && BigInt(number) > BigInt(this.INT64_MAX)) {
                    return false;
                }
                break;
            case 'positive_number':
                number = ~~Number(value);
                if (value.trim().length > 0 && !(String(number) === value && number >= 0)) {
                    return false;
                }
                break;
            case 'float':
                expr = /^[-+]?[0-9]+.[0-9]+$/;
                number = this.getUnformattedNumber(trimmedValue, expr, element);
                if (number.length > 0 && !(number == parseInt(number, 10) || number.match(expr))) {
                    return false;
                }
                break;
            case 'decimal':
                expr = new RegExp('^([-]?[0-9]+).[0-9]{' + args + '}$');
                number = this.getUnformattedNumber(value, expr, element);
                if (number.length > 0) {
                    var parts1 = number.match(expr);
                    if (parts1 === null || !(number == parseInt(number, 10) || this.isValid(parts1[1], 'number', args, elementIdOrName, type, formId))) {
                        return false;
                    }
                }
                break;
            case 'currency':
                expr = /^[-+]?\d{1,10}(?:\.\d{0,2})?$/;
                number = this.getUnformattedNumber(value, expr, element);
                if (number.length > 0 && !(!isNaN(parseInt(number)) && expr.test(number))) {
                    return false;
                }
                break;
            case 'same':
                if (value.length > 0 && value != $('#' + args).val()) {
                    return false;
                }
                break;
            case 'file':
                var files1 = this.files(formId);
                if (files1.hasOwnProperty(elementIdOrName) && _.isObject(files1[elementIdOrName])) {
                    var extensions = args.split(',');
                    var extension = files1[elementIdOrName].name.split('.').pop().toLowerCase();
                    if (extensions.indexOf(extension) === -1) {
                        return false;
                    }
                    if (files1[elementIdOrName].size > config('app.maxFileSizeBytes')) {
                        return false;
                    }
                }
                break;
            case 'color':
                return value.length === 0 || value.match(new RegExp(/^#[\da-fA-F]{6}$/)) !== null;
        }
        return true;
    },
    showError: function showError(group, validator, args, alertErrors) {
        alertErrors = alertErrors || false;
        var message = '';
        switch(validator){
            case 'required':
                message = ts('Value must not be empty');
                break;
            case 'min_length':
                message = ts('Value must have a minimum length of %1 characters', [
                    args
                ]);
                break;
            case 'max_length':
                message = ts('Value must have a maximum length of %1 characters', [
                    args
                ]);
                break;
            case 'alpha':
                message = ts('Value must consist of letters only');
                break;
            case 'alpha_num':
                message = ts('Value must consist of letters and numbers only');
                break;
            case 'alpha_num_dash':
                message = ts('Value must consist of letters, numbers, dashes, and underscores only');
                break;
            case 'min':
                message = ts('Value must be a number, bigger than %1', [
                    args
                ]);
                break;
            case 'max':
                message = ts('Value must be a number, smaller than %1', [
                    args
                ]);
                break;
            case 'email':
                message = ts('Value must be a valid email address (in format address@domain.tld)');
                break;
            case 'phone':
                message = ts('Value must be a valid international phone number (starting with +, 00 or 011)');
                break;
            case 'url':
                message = ts('Value must be a valid URL (starting with http:// or https://)');
                break;
            case 'date':
                message = ts('Value must be a valid date (must not contain a time)');
                break;
            case 'time':
                message = ts('Value must be a valid time (in format 01:01 or 01:01:01)');
                break;
            case 'date_time':
                message = ts('Value must be a valid date and time (a date followed by ' + 'a space and a time in in format 01:01 or 01:01:01)');
                break;
            case 'before_or_equal':
            case 'after_or_equal':
                var beforeOrAfter;
                if (args.toLowerCase() === 'now') {
                    beforeOrAfter = ts('current date and time');
                } else {
                    beforeOrAfter = args.split(' ').length === 1 ? i18n("".concat(args, " 00:00:00")).dateTime() : i18n(args).dateTime();
                }
                message = ts("Value must be set ".concat(validator === 'before_or_equal' ? 'before' : 'after', " than or equal to %1"), [
                    beforeOrAfter
                ]);
                break;
            case 'number':
                message = ts('Value must be a positive or negative number between %1 and %2', [
                    this.INT64_MIN,
                    this.INT64_MAX
                ]);
                break;
            case 'positive_number':
                message = ts('Value must be a positive number');
                break;
            case 'float':
                message = ts('Value must be a valid decimal number');
                break;
            case 'decimal':
                message = ts('Value must be a decimal number, having %1 digits after decimal point', [
                    args
                ]);
                break;
            case 'currency':
                message = ts('Value must be a valid currency value (1-10 digits ' + 'before and 0-2 digits after the decimal point)');
                break;
            case 'same':
                message = ts('Value must match "%1"', [
                    $('#' + args).val()
                ]);
                break;
            case 'file':
                var maxFileSizeMb = Math.round(config('app.maxFileSizeBytes') / 1048576 * 100) / 100;
                var allowedExtensions = args.replace(/,/g, ', ');
                message = ts('File must not exceed file size of %1 MB have one of the following extensions: "%2"', [
                    maxFileSizeMb,
                    allowedExtensions
                ]);
                break;
            case 'color':
                message = ts('Value must be a valid color HEX code (e.g. #00ffff).');
                break;
        }
        if (alertErrors === true) {
            return error(message);
        }
        // Add CSS error class to element group
        group.addClass(this.errorGroupClass);
        $('<span/>', {
            class: this.errorClass,
            html: message
        }).appendTo(group);
    },
    determineType: function determineType(obj) {
        if (obj.prop('tagName').toUpperCase() === 'TEXTAREA') {
            // If we find any iframe within a textarea group, assume it's a WYSIWYG editor
            if (obj.parent().find('iframe').length === 1) {
                return 'wysiwyg';
            }
            return 'textArea';
        }
        if (obj.prop('tagName').toUpperCase() === 'SELECT') {
            return 'select';
        }
        if (obj.prop('tagName').toUpperCase() === 'INPUT') {
            // Level- or multi selects must have a hidden input to store the actual value
            // Since date or time pickers also have a hidden input, we must make sure to skip them
            if (obj.attr('type').toUpperCase() === 'HIDDEN' && !obj.hasClass('ui-date-time-picker')) {
                // Level selects additionally must have a data-levels attribute
                // This is actually only require for validation
                if (obj.attr('data-levels')) {
                    return 'levelSelect';
                }
                return 'multiSelect';
            }
            if (obj.attr('type').toUpperCase() === 'CHECKBOX') {
                return 'checkbox';
            }
            if (obj.attr('type').toUpperCase() === 'FILE') {
                return 'file';
            }
            return 'input';
        }
        throw 'Could not determine element type';
    },
    getValue: function getValue(obj, type) {
        switch(type){
            case 'select':
            case 'input':
            case 'wysiwyg':
            case 'textArea':
                return obj.val();
            // A checkbox may have a value, but we are not interested in it
            // For us it's important if it's checked or not
            case 'checkbox':
                return obj.is(':checked');
            // Just parse the JSON data of multi- and level selects
            // Validators must care about how to handle that
            case 'multiSelect':
            case 'levelSelect':
                return obj.val().length > 0 ? JSON.parse(obj.val()) : '';
            // Since file inputs can't be populated we have to set an HTML attribute
            // This is what we are going to check here
            case 'file':
                return parseInt(obj.attr('data-file')) === 1;
            default:
                throw 'Could not read value';
        }
    },
    getUnformattedNumber: function getUnformattedNumber(number, expr, element) {
        number = number.trim();
        if (number.match(expr) !== null) {
            return number;
        }
        return unformatNumber(number, element ? element.data('number-format') : null);
    }
};
App.Validator = Validator;
export { Validator };
