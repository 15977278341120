function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { App } from 'Shared/resources/assets/app/js/App';
import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
var ConfigStore = {
    get: function get() {
        return getTopWindowDocument().appConfig || {};
    },
    set: function set(store) {
        getTopWindowDocument().appConfig = store;
    }
};
var Config = {
    has: function has(key) {
        return ConfigStore.get().hasOwnProperty(key);
    },
    get: function get(key, defaultValue) {
        if (!this.has(key)) {
            if (typeof defaultValue === 'undefined') {
                throw Error("Config value not available for key: ".concat(key));
            }
            return defaultValue;
        }
        return ConfigStore.get()[key];
    },
    set: function set(key, value) {
        var config = typeof key === 'object' ? key : _define_property({}, key, value);
        ConfigStore.set(_object_spread({}, ConfigStore.get(), config));
    }
};
App.Config = Config;
export { Config };
