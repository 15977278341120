function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { App } from 'Shared/resources/assets/app/js/App';
import { onDocumentReady } from 'Shared/resources/assets/app/js/helpers/domHelpers';
import { getApp } from 'Shared/resources/assets/app/js/helpers/appHelpers';
import { config } from 'Shared/resources/assets/app/js/helpers/configHelpers';
import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
import { Pluralizer } from 'Shared/resources/assets/app/js/utils/i18n/Pluralizer';
import { Ajax } from 'Shared/resources/assets/app/js/utils/Ajax';
import { laroute } from 'Shared/resources/assets/app/js/laroute';
var topWindowDocument = getTopWindowDocument();
/**
 * This is a rough JS port of the StaffCloud\Shared\Library\Translation\Translator class.
 */ var Translator = {
    TRANSLATED_IDENTIFIER: '',
    PLACEHOLDER: '%',
    addTranslations: function addTranslations(translations) {
        topWindowDocument.translations = _object_spread({}, topWindowDocument.translations || {}, translations);
    },
    translate: function translate(string, params) {
        string = this.translateString(string);
        return this.replaceParams(string, params);
    },
    translatePlural: function translatePlural(string, quantity, params) {
        string = this.translateString(string);
        string = Pluralizer.init(string, quantity).extract();
        return this.replaceParams(string, params);
    },
    translateString: function translateString(string) {
        if (typeof string !== 'string') {
            return string;
        }
        if ((topWindowDocument.translations || {}).hasOwnProperty(string)) {
            string = topWindowDocument.translations[string];
        } else {
            topWindowDocument.missingTranslations = (topWindowDocument.missingTranslations || []).concat([
                string
            ]);
            this.addTranslations(_define_property({}, string, string));
        }
        return string;
    },
    replaceParams: function replaceParams(string, params) {
        if (typeof params === 'undefined') {
            return string;
        }
        var output = string;
        for(var x = 0, last = params.length; x < last; x++){
            output = output.replace(this.PLACEHOLDER + (x + 1), params[x]);
        }
        return output;
    },
    addMissingTranslations: function addMissingTranslations() {
        var application = getApp();
        var module = config('app.module');
        if (application.length === 0) {
            return;
        }
        var missingTranslations = (topWindowDocument.missingTranslations || []).filter(function(key) {
            return key !== null && key.length !== 0;
        });
        // Don't send invalid translations
        if (missingTranslations.length === 0) {
            return;
        }
        Ajax.post(laroute.route('jsTranslation'), {
            missingTranslations: missingTranslations,
            application: application,
            module: module
        });
        topWindowDocument.missingTranslations = [];
    }
};
onDocumentReady(function() {
    setInterval(Translator.addMissingTranslations, 5000);
});
App.Translator = Translator;
export { Translator };
