import PropTypes from 'prop-types';
import { ENTITY_IDS } from 'Core/resources/assets/js/backendStaticEntities/entityIds';
var DynamicFormType = PropTypes.shape({
    entityId: PropTypes.oneOf(ENTITY_IDS).isRequired,
    forceSingleColumn: PropTypes.bool.isRequired,
    hasButtons: PropTypes.bool.isRequired,
    hasSectionTitles: PropTypes.bool.isRequired,
    identifier: PropTypes.string.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
    isSearchForm: PropTypes.bool.isRequired,
    isSingleColumn: PropTypes.bool.isRequired,
    skipLocks: PropTypes.bool.isRequired
});
export { DynamicFormType };
