import PropTypes from 'prop-types';
var EventType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    location: PropTypes.string,
    location_id: PropTypes.number,
    planner: PropTypes.string,
    planner_id: PropTypes.number,
    client: PropTypes.string,
    client_id: PropTypes.number,
    quantity: PropTypes.number.isRequired,
    applied: PropTypes.number.isRequired,
    assigned_provisional: PropTypes.number.isRequired,
    assigned: PropTypes.number.isRequired,
    confirmed: PropTypes.number.isRequired,
    denied: PropTypes.number.isRequired,
    status_changes: PropTypes.shape({
        difference_applied: PropTypes.number
    })
});
export { EventType };
