var DynamicAttributeType = {
    BOOLEAN: 'boolean',
    COLOR: 'color',
    DATE: 'date',
    DATETIME: 'datetime',
    DECIMAL: 'decimal',
    EMAIL: 'email',
    FILE: 'file',
    INDEX: 'index',
    LEVEL_SELECT: 'level_select',
    MONEY: 'money',
    MULTI_SELECT: 'multi_select',
    NUMBER: 'number',
    PHONE: 'phone',
    WYSIWYG: 'wysiwyg',
    SELECT: 'select',
    TEXT: 'text',
    TEXTAREA: 'text_area',
    TIME: 'time',
    URL: 'url'
};
export { DynamicAttributeType };
