function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui'; // needed by rspack, delete following jquery-ui import(s)
// import 'jquery-ui/ui/widgets/draggable';
import { App } from 'Shared/resources/assets/app/js/App';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { isIFrame, isParentSameDomain } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
import { getCsrfToken } from 'Shared/resources/assets/app/js/utils/csrfToken';
var Popup = {
    /**
     * The popup configuration.
     *
     * seamless {false|bool}    Popup will get seamless design.
     * height   {int}           Popup content height
     * width    {int}           Popup content width
     * onReady  {callback}      Callback will be fired as soon as popup has loaded
     * onSubmit {callback}      Callback will be fired before a form in popup is submitted (optional)
     * onClose  {callback}      Callback will be fired before the popup is closed by user (optional)
     */ options: {},
    /**
     * Will be set to true as soon as popup content has finished loading.
     */ loadingComplete: false,
    /**
     * The popup height.
     */ height: 0,
    /**
     * The popup width.
     */ width: 0,
    /**
     * Create a new popup with an IFrame as content
     *
     * Attention: This will only work with URL's from same domain and protocol!
     * @see https://developer.mozilla.org/en-US/docs/Web/Security/Same-origin_policy
     *
     * @todo Remove the scrolling attribute as soon as all browsers support CSS overflow for iframes
     *
     * @param {string} url Must be an absolute URL, use laroute to generate
     * @param {string} title Popup title
     * @param {object} options
     */ initUrl: function initUrl(url, title, options) {
        var html = '<iframe id="ui-popup-iframe" src="'.concat(url, '" scrolling="no"></iframe>');
        this.setup(title, html, options);
    },
    initPost: function initPost(url, title, options, data) {
        var iframeName = 'ui-popup-iframe';
        var formName = 'ui-popup-form';
        var html = '<form id="'.concat(formName, '" target="').concat(iframeName, '" action="').concat(url, '" method="POST" style="display: none;">');
        html += '<input type="hidden" name="_token" value="'.concat(getCsrfToken(), '">');
        for(var prop in data){
            if (Object.hasOwn(data, prop)) {
                html += '<input type="text" name="'.concat(prop, '" value="').concat(data[prop], '">');
            }
        }
        html += '<input type="submit" value="Submit"></form>';
        html += '<iframe id="'.concat(iframeName, '" name="').concat(iframeName, '" scrolling="no"></iframe>');
        this.setup(title, html, options);
        document.getElementById(formName).submit();
    },
    /**
     * Create a new popup with HTML as content
     *
     * @param {string|jQuery} html Must be valid HTML or a jQuery object
     * @param {string} title Popup title
     * @param {object} options
     */ initHtml: function initHtml(html, title, options) {
        if (_instanceof(html, $)) {
            // Unfortunately the html method of jQuery only returns the innerHtml.
            // We need the full HTML so
            html = $('<div/>').html(html).html();
        }
        this.setup(title, html, options);
    },
    /**
     * Create popup HTML prepare observers
     *
     * @param {string} title Popup title
     * @param {string} content Must be valid HTML
     * @param {object} options
     */ setup: function setup(title, content, options) {
        this.options = options || {};
        var seamless = this.options.seamless ? 'class="ui-popup-seamless"' : '';
        var html = '\n                <div id="ui-popup-overlay" class="ui-popup-overlay">\n                    <div id="ui-popup" '.concat(seamless, '>\n                        <div id="ui-popup-drag-handler">\n                            <i class="fa fa-arrows-alt"></i>').concat(ts('Click and drag to change the popup position.'), '\n                        </div>\n                        <div id="ui-popup-header">\n                            <div id="ui-popup-title">').concat(ts(title), '</div>\n                            <div id="ui-popup-close" title="').concat(ts('Close'), '">\n                                <i class="fa fa-remove"></i>\n                            </div>\n                        </div>\n                        <div id="ui-popup-content" class="').concat(this.options.contentClassName || '', '">').concat(content, "</div>\n                    </div>\n                </div>");
        // Create popup object
        // Bind events
        var popup = $.parseHTML(html);
        // Close existing popups
        this.close(false);
        // Inject new popup
        // Attach observers
        this.injectIntoDom(popup);
        this.handleClose();
        // Set initial dimensions
        this.setHeight(this.options.height);
        this.setWidth(this.options.width);
    },
    /**
     * Injects HTML into DOM and observes loading of content.
     *
     * @param {jQuery} popup Popup object to be injected
     */ injectIntoDom: function injectIntoDom(popup) {
        var _this = this;
        var $iframe = $('#ui-popup-iframe', popup);
        // Observe loading status of popup content
        // iFrames have to be handled different
        if ($iframe.length) {
            // Attach jQuery observer
            $iframe.on('load', function() {
                return _this.setLoadingComplete(popup);
            });
            // Inject HTML AFTER attaching observer
            $('.ui-body').append(popup);
            // This is a shorthand for the submit observer
            if (this.options.onSubmit) {
                this.observeFormSubmit(this.options.onSubmit);
            }
        } else {
            // Inject HTML BEFORE setting loading state
            $('.ui-body').append(popup);
            // Loading completed. Hopefully.
            this.setLoadingComplete(popup);
        }
        $('#ui-popup', popup).draggable({
            handle: '#ui-popup-drag-handler'
        });
    },
    /**
     * Sets content loading state to finished and executes possible events.
     *
     * @param {jQuery} popup Popup object to be injected
     */ setLoadingComplete: function setLoadingComplete(popup) {
        this.loadingComplete = true;
        if (this.options.onReady) {
            this.options.onReady(popup);
        }
    },
    /**
     * Observer which watches for a form being submitted within the iFrame
     *
     * The important difference is that the callback is only fired after the target page has finished loading.
     * This is especially useful if the submitted form data will be displayed afterwards.
     * Be aware that this method is not only fired for forms but for any page reload within the iFrame.
     *
     * @param callback
     */ observeFormSubmit: function observeFormSubmit(callback) {
        var _this = this;
        // We have to wait until the iFrame content has finished loading.
        // Otherwise, the callback will also be fired for the initial load.
        var interval = setInterval(function() {
            if (_this.loadingComplete === true) {
                $('#ui-popup-iframe').off().on('load', function() {
                    return callback();
                });
                clearInterval(interval);
            }
        }, 500);
    },
    /**
     * Set popup height
     *
     * Provide actual height of your content, this method will care about box model.
     *
     * @param {int} height Height in Px
     */ setHeight: function setHeight(height) {
        var $content = $('#ui-popup-content:not(.ui-popup-body)');
        if (!$content.length) {
            return;
        }
        var padding = parseInt($content.css('padding-top')) + parseInt($content.css('padding-bottom'));
        $content.css('height', "".concat(height + padding, "px"));
        this.height = height + padding + 86;
        if (isIFrame()) {
            this.adjustParentPopupDimensions();
        }
    },
    /**
     * Get the popup height.
     *
     * @returns {number}
     */ getHeight: function getHeight() {
        return this.height;
    },
    /**
     * Set popup width
     *
     * Provide actual width of your content, this method will care about box model.
     *
     * @param {int} width Width in Px
     */ setWidth: function setWidth(width) {
        var $content = $('#ui-popup-content');
        var padding = parseInt($content.css('padding-left')) + parseInt($content.css('padding-right'));
        $content.css('width', "".concat(width + padding, "px"));
        var outerWidth = $content.outerWidth();
        var margin = "calc(50% - ".concat(outerWidth / 2, "px)");
        // The height of the popup will adjust automatically, the width will not.
        // Thanks to border-box we can just use the same width as for the content container.
        // Additionally, we have to set the left and right margins in order to center the popup.
        $('#ui-popup').css('width', outerWidth).css('margin-left', margin).css('margin-right', margin);
        this.width = width + padding + 40;
        if (isIFrame()) {
            this.adjustParentPopupDimensions();
        }
    },
    /**
     * Get the popup width.
     *
     * @returns {number}
     */ getWidth: function getWidth() {
        return this.width;
    },
    /**
     * Automatically adjust popup height.
     *
     * Since a lof of popups are using IFrames to display their contents, we have a problem with the popup height.
     * The browser doesn't automatically adjust the container height if the content changes, we have to do this
     * manually. We'll get the height of the IFrame content and put it on the container.
     *
     * @param {boolean} repeatDelayed For internal usage only
     * @param {string} containerId For internal usage only
     */ adjustHeight: function adjustHeight(repeatDelayed, containerId) {
        repeatDelayed = repeatDelayed !== false;
        // We should repeat the action with a small delay
        // Some DOM manipulations may take some time
        if (repeatDelayed === true) {
            setTimeout(function() {
                Popup.adjustHeight(false, containerId);
            }, 500);
        }
        var height = containerId ? $("#".concat(containerId)).outerHeight() : $(App.Popup.childDocument()).find('body > div').outerHeight();
        App.Popup.parentDocument(true).setHeight(height);
    },
    /**
     * Adjust the parent popup sizes.
     *
     * There are situations when loading a popup widget inside another popup and the size of the parent popup
     * is smaller than the size of the widget. In such cases we must assure the parent popup is resized accordingly to
     * avoid scrollbars appearing. (See Staff Planning -> Edit event details box)
     */ adjustParentPopupDimensions: function adjustParentPopupDimensions() {
        try {
            var parentPopup = App.Popup.parentDocument(true);
            var childPopup = App.Popup.childDocument(true);
            if (parentPopup === childPopup) {
                return;
            }
            var height = childPopup.getHeight() + 30;
            var width = childPopup.getWidth() + 40;
            if (parentPopup.getHeight() - 86 < height) {
                parentPopup.setHeight(height);
            }
            if (parentPopup.getWidth() < width) {
                parentPopup.setWidth(width);
            }
        } catch (e) {
        // Nothing to do
        }
    },
    /**
     * Set close event triggers
     *
     * Popup will be closed if someone clicks on the close button (you don't say...) and if someone
     * clicks outside the popup in the overlay.
     */ handleClose: function handleClose() {
        var _this = this;
        $('#ui-popup-close').on('click', this.close.bind(this, true));
        // This must be handled in a separate event
        $('#ui-popup-overlay').on('click', function(event) {
            if (event.target.id === 'ui-popup-overlay') {
                _this.close(true);
            }
        });
    },
    /**
     * Closes popup
     *
     * @param {boolean} closedByUser
     */ close: function close(closedByUser) {
        if (closedByUser && this.options.onClose) {
            this.options.onClose();
        }
        this.remove(closedByUser);
        if (closedByUser) {
            var parentPopup = window.parent.App.Popup.parentDocument(true);
            if (parentPopup.getWidth() && parentPopup.getHeight()) {
                parentPopup.setWidth(parentPopup.options.width);
                parentPopup.adjustHeight();
            }
        }
    },
    /**
     * Remove popup from DOM
     *
     * @param {boolean} closedByUser
     */ remove: function remove(closedByUser) {
        $('#ui-popup').remove();
        $('#ui-popup-overlay').remove();
        $('.ui-body').off('click');
        this.loadingComplete = false;
        this.height = 0;
        this.width = 0;
        if (closedByUser) {
            this.options.onClose = false;
            this.options.onReady = false;
        }
    },
    /**
     * Returns document object of parent window
     *
     * @param {boolean} returnPopupObject If true it will return an instance of this object instead of document
     *
     * @returns {Document}
     */ parentDocument: function parentDocument(returnPopupObject) {
        var documentObject = document;
        // We are in child and have IFrame
        if (isIFrame() && isParentSameDomain()) {
            documentObject = window.parent.document;
        }
        if (returnPopupObject === true) {
            if (documentObject.hasOwnProperty('App')) {
                return documentObject.App.Popup;
            }
            // Fallback to the document itself since there is no App in the parent. This case could happen when
            // integrating recruiting into another page.
            return document.App.Popup;
        }
        return documentObject;
    },
    /**
     * Returns document object of popup content window
     *
     * Attention: This will return the same document as .parentDocument() if popup is not using an IFrame!
     * Also, this function will not work if you are using the IFrame and it has not yet finished loading.
     *
     * @param {boolean} returnPopupObject If true it will return an instance of this object instead of document.
     *
     * @returns {HTMLDocument|Popup}
     */ childDocument: function childDocument(returnPopupObject) {
        var iframe = this.parentDocument().getElementById('ui-popup-iframe');
        var documentObject = iframe === null || self !== top ? document : iframe.contentWindow.document;
        if (returnPopupObject === true) {
            if (documentObject.hasOwnProperty('App')) {
                return documentObject.App.Popup;
            }
            // Fallback to the document itself since there is no App in the parent. This case could happen when
            // integrating recruiting into another page.
            return document.App.Popup;
        }
        return documentObject;
    }
};
// Before removing:
// see occurrences of "App.Popup" in this file
// see also Core/resources/assets/app/js/ui/collection.js -> "this.windowToOpenOn.App.Popup"
App.Popup = Popup;
export { Popup };
